import React from 'react';
import { Provider } from 'react-redux';
import createStore from './createStore';
export default (function (_ref) {
  var element = _ref.element;
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  var store = createStore();
  return React.createElement(Provider, {
    store: store
  }, element);
});