var noScript = function noScript(iframe) {
  var noscript = document.createElement('noscript');
  noscript.innerHTML = iframe;
  return noscript;
};

var Script = function Script(s) {
  var script = document.createElement('script');
  script.innerHTML = s;
  return script;
};

var extractScriptContent = function extractScriptContent(s) {
  var start = '<script>';
  var end = '</script>';
  var res = s.substring(s.indexOf(start) + start.length, s.indexOf(end));
  return res;
};

var extractNoScriptContent = function extractNoScriptContent(s) {
  var start = '<noscript>';
  var end = '</noscript>';
  var res = s.substring(s.indexOf(start) + start.length, s.indexOf(end));
  return res;
};

export var initialize = function initialize(gtmHead, gtmBody) {
  var headScript = extractScriptContent(gtmHead);
  var bodyScript = extractNoScriptContent(gtmBody);

  if (typeof document !== 'undefined') {
    document.head.insertBefore(Script(headScript), document.head.childNodes[0]);
    document.body.insertBefore(noScript(bodyScript), document.body.childNodes[0]);
  }
};