var size = {
  M: '425px',
  T: '768px',
  D: '1280px'
};
export var device = {
  A: "(min-width: 0px) ",
  M: "(max-width: " + '767px' + ") ",
  T: "(min-width: " + size.T + ") and (max-width: " + size.D + ")",
  D: "(min-width: " + size.D + ")"
};