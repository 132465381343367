import _ from 'lodash';

var filterActiveSections = function filterActiveSections(dom) {
  return dom.filter(function (section) {
    return section.active;
  });
};

var filterActiveComponents = function filterActiveComponents(dom) {
  return dom.map(function (section) {
    section.components = section.components.filter(function (component) {
      return component.active;
    });
    return section;
  });
};

var filterActiveFields = function filterActiveFields(dom) {
  return dom.map(function (section) {
    section.components.map(function (component) {
      _.mapKeys(component.fields, function (value, key) {
        if (!value.active) {
          _.unset(component.fields, key);
        }
      });

      return component;
    });

    _.mapKeys(section.fields, function (value, key) {
      if (!value.active) {
        _.unset(section.fields, key);
      }
    });

    return section;
  });
};

var extractActiveValue = function extractActiveValue(dom) {
  return filterActiveFields(filterActiveComponents(filterActiveSections(_.cloneDeep(dom))));
};

export { extractActiveValue };