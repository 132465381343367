import React from 'react';
import styled from 'styled-components';
var Wrapper = styled.section.withConfig({
  displayName: "Container__Wrapper",
  componentId: "sc-1rc0v0t-0"
})(["margin:0 auto auto;width:100%;flex-grow:1;"]);

var Container = function Container(props) {
  return React.createElement(Wrapper, null, props.children);
};

export default Container;