// prefer default export if available
var preferDefault = function preferDefault(m) {
  return m && m.default || m;
};

exports.components = {
  "component---src-templates-page-js": function componentSrcTemplatesPageJs() {
    return import("/root/project/src/templates/page.js"
    /* webpackChunkName: "component---src-templates-page-js" */
    );
  },
  "component---src-pages-404-js": function componentSrcPages404Js() {
    return import("/root/project/src/pages/404.js"
    /* webpackChunkName: "component---src-pages-404-js" */
    );
  }
};