function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["  \n    ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }

import { createGlobalStyle } from 'styled-components';
var GlobalFonts = createGlobalStyle(_templateObject(), function (props) {
  return props.fontFaces;
});
export default GlobalFonts;