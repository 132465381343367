import React from 'react';
import styled from 'styled-components';
import Structure from '../templates/Structure';
var Container = styled.div.withConfig({
  displayName: "sc-404__Container",
  componentId: "sc-1bfoog4-0"
})(["display:flex;flex-direction:column;align-items:center;justify-content:center;background:black;color:white;height:80vh;& h1{font-size:48px;line-height:64px;}"]);

var NotFoundPage = function NotFoundPage() {
  return React.createElement(Structure, null, React.createElement(Container, null, React.createElement("h1", null, "404"), React.createElement("h2", null, "NOT FOUND"), React.createElement("p", null, "You just hit a route that doesn't exist... the sadness.")));
};

export default NotFoundPage;