import wrapWithProvider from './src/state/wrap-with-provider';
import SmoothScroll from 'smooth-scroll';
export var wrapRootElement = wrapWithProvider;
export var onRouteUpdate = function onRouteUpdate(_ref) {
  /*if (hash) {
      window.setTimeout(() => {
          let scroll = new SmoothScroll();
          let anchor = document.querySelector(hash);
          if (anchor) {
              scroll.animateScroll(anchor);
          }
      }
      , 100);
  }*/

  var hash = _ref.location.hash;
};